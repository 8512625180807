<template>
  <aRow class="link-generator" :gutter="20">
    <aCol :span="16">
      <aRow :gutter="20">
        <aCol :span="20">
          <label> Copiar link para pagamento </label>
          <a-form-item>
            <a-input
              class="pay-link"
              :placeholder="`pagamento.viajarresorts.com.br/b2c/${tempContract.id}/xxxxxxxxxxxxxxx`"
              v-decorator="[
                `payment_methods_payment_link_url_${paymentLinkId}`,
              ]"
            >
              <a-tooltip slot="suffix" title="Copiar o link">
                <a-icon
                  type="copy"
                  style="color: rgba(0, 0, 0, 0.45)"
                  @click="copyToClipboard"
                />
              </a-tooltip>
            </a-input>
          </a-form-item>
        </aCol>

        <aCol :span="4">
          <img
            v-if="
              tempContract[`payment_methods_payment_link_url_${paymentLinkId}`]
            "
            @click="openWhatsapp"
            class="wpp"
            src="@/assets/images/dashboard/contracts/whatsapp-link.png"
            alt="wpp"
          />
        </aCol>
      </aRow>
    </aCol>

    <aCol :span="8" class="a-right">
      <aButton @click="generatePaymentLink" :loading="loading" type="primary">
        Gerar link de pagamento
        <a-icon :type="loading ? 'loading' : 'link'" />
      </aButton>
    </aCol>
  </aRow>
</template>

<script>
export default {
  name: "PaymentLinkGenerateLink",
  props: {
    form: Object,
    paymentLinkId: Number,
    tempContract: Object,
    contract: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    this.form.setFieldsValue({
      [`payment_methods_payment_link_url_${this.paymentLinkId}`]:
        this.tempContract[
          `payment_methods_payment_link_url_${this.paymentLinkId}`
        ],
    });
  },
  methods: {
    generatePaymentLink() {
      this.loading = true;
      this.$http
        .post("/payment-token", {
          id: this.contract.id,
          payment_link_id: this.paymentLinkId,
          user: this.$store.state.userData,
          modified_by: {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
            avatar: this.contract.user.avatar,
            email: this.contract.user.email,
            mobile_phone: this.contract.user.mobile_phone,
            whatsapp: this.contract.user.mobile_phone
              ? this.$store.state.userData.meta.mobile_phone
                  .replace(" ", "")
                  .replace(" ", "")
                  .replace("-", "")
                  .replace("(", "")
                  .replace(")", "")
              : "",
          },
        })
        .then(({ data }) => {
          this.loading = false;
          this.showPasswordModal = false;

          const dateReference = this.tempContract.created.split("-");

          this.tempContract[
            `payment_link_reference_${this.paymentLinkId}`
          ] = `LP${this.paymentLinkId}${dateReference[0]}${dateReference[1]}${this.tempContract.id}`;

          this.$message.success("Link gerado com sucesso!");

          let updtContract = {};
          let paymentLink = "";

          if (this.tempContract.company_branch == "Voe Simples") {
            paymentLink = `https://pagamento.voesimples.com.br/b2c/${data.jwt}`;

            this.form.setFieldsValue({
              [`payment_methods_payment_link_url_${this.paymentLinkId}`]:
                paymentLink,
            });

            updtContract[
              `payment_methods_payment_link_base_url_${this.paymentLinkId}`
            ] = "voesimples";

            updtContract[
              `payment_methods_payment_link_url_${this.paymentLinkId}`
            ] = paymentLink;
          } else {
            paymentLink = `https://pagamento.viajarresorts.com.br/b2c/${data.jwt}`;

            this.form.setFieldsValue({
              [`payment_methods_payment_link_url_${this.paymentLinkId}`]:
                paymentLink,
            });

            updtContract[
              `payment_methods_payment_link_base_url_${this.paymentLinkId}`
            ] = "viajarresorts";

            updtContract[
              `payment_methods_payment_link_url_${this.paymentLinkId}`
            ] = paymentLink;
          }

          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: this.tempContract.id,
            module: "contract",
            action: `payment-link-${this.paymentLinkId}-generate`,
            description: `<b class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</b> gerou um LINK DE PAGAMENTO para o contrato <b>ID ${this.tempContract.id}</b>.  <br><br> <b>Link gerado:</b> ${paymentLink}<br><br>`,
          });

          this.onGenerateLinkUpdateContract(updtContract);
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
          this.loading = false;
        });
    },
    onGenerateLinkUpdateContract(updtContract) {
      updtContract.id = this.tempContract.id;

      updtContract[`payment_link_reference_${this.paymentLinkId}`] =
        this.tempContract[`payment_link_reference_${this.paymentLinkId}`];

      updtContract[
        `payment_methods_payment_link_total_value_${this.paymentLinkId}`
      ] =
        this.tempContract[
          `payment_methods_payment_link_total_value_${this.paymentLinkId}`
        ];

      updtContract[
        `payment_methods_payment_link_installments_${this.paymentLinkId}`
      ] =
        this.tempContract[
          `payment_methods_payment_link_installments_${this.paymentLinkId}`
        ];

      updtContract[
        `payment_methods_payment_link_first_installment_${this.paymentLinkId}`
      ] =
        this.tempContract[
          `payment_methods_payment_link_first_installment_${this.paymentLinkId}`
        ];

      updtContract[
        `payment_methods_payment_link_tax_value_${this.paymentLinkId}`
      ] =
        this.tempContract[
          `payment_methods_payment_link_tax_value_${this.paymentLinkId}`
        ];

      updtContract[
        `payment_methods_payment_link_total_taxes_${this.paymentLinkId}`
      ] =
        this.tempContract[
          `payment_methods_payment_link_total_taxes_${this.paymentLinkId}`
        ];

      updtContract[
        `payment_methods_payment_link_advance_fee_value_${this.paymentLinkId}`
      ] =
        this.tempContract[
          `payment_methods_payment_link_advance_fee_value_${this.paymentLinkId}`
        ];

      updtContract.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      setTimeout(() => {
        this.$message.info("Gravando os dados do link no contrato...", 3);

        this.$http
          .post("/contract/update-multi-meta", updtContract)
          .then(() => {
            this.$message.success(`Dados do link atualizados no contrato!`);
          });
      }, 300);
    },
    openWhatsapp() {
      const baseUrl = "https://api.whatsapp.com/send?phone=55";
      const theNumber = this.tempContract.the_customer.mobile_phone
          .replace(" ", "")
          .replace(" ", "")
          .replace("-", "")
          .replace("(", "")
          .replace(")", ""),
        theText = `Olá ${this.tempContract.the_customer.first_name}!%0A%0ASegue o link para pagamento: %0A ${this.tempContract.payment_methods_payment_link_url}`;

      window.open(`${baseUrl}${theNumber}&text=${theText}`, "_blank");
    },
    copyToClipboard() {
      let copyText = "";
      setTimeout(() => {
        copyText = document.getElementById(
          `payment_methods_payment_link_url_${this.paymentLinkId}`
        );
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
        this.$message.info("Copiado!");
      }, 20);
    },
  },
};
</script>

<style lang="sass">
.link-generator
  .pay-link
    input
      pointer-events: none
      font-size: 11px
      background: #f6f6f6 !important
</style>

<style lang="sass" scoped>
$green: #00aa21
.link-generator
  .obs
    margin-top: -14px
    font-size: 13px
    color: #aab2bd
    line-height: 1
  .wpp
    position: relative
    top: 24px
    cursor: pointer
  input
    border-color: #ddd
    text-transform: none
    font-size: 12px
    font-weight: 500
    color: #999
    background: #f9f9f9
  label
    color: #ff4b00
    font-weight: 600
    font-size: 13px
  button
    margin-top: 25px
    background-color: #ff4b00
    border-color: #ff4b00
  button.mail
    background-color: $green !important
    border-color: $green !important
</style>